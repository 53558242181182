.on-sale-cart-sale {
  position: relative;
  display: inline-block;
}

.on-sale-card {
  position: relative;
  background-color: red;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  border: 3px groove #ff6550;
  background-color: #f26565;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
}

.l-icon {
  position: absolute;
  top: -8px;
  right: -4px;
  color: black;
  font-size: 14px;
}
