.cointener{
   padding: 1rem; 
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 1rem;
}
.order-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
}