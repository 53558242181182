.home-container {
  background-image: url(https://www.thedailymeal.com/img/gallery/you-should-think-twice-about-bagging-your-own-groceries-at-the-store/l-intro-1681220544.jpg);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.home-title {
  background-color: #26e348;
  color: white;
  padding: 10px;
  margin-right: 20px;
  font-size: 3.5rem;
  font-family: "Anton", sans-serif;
}
.home-text {
  display: block;
}
.products {
  display: block;
  margin-top: 10px;
  padding: 10px;
  background-color: #26e348;
  color: white;
  text-decoration: none;
  font-weight: 700;
}
