.navbar {
  height: 15vh;
  background-color: #26e348;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.links {
  width: auto;
  display: flex;
  justify-content: space-around;
  margin-right: 2rem;
}

.logo {
  width: 150px;
  height: 100%;
  padding: 10px;
}

.activeStyles {
  padding: 10px;
  text-decoration: none;
  background-color: #d8ec81;
  border-radius: 15px;
  color: #000000;
}

.classicStyles {
  padding: 10px;
  text-decoration: none;
  border-radius: 15px;
  color: #ffffff;
  
}
.cartIconContainer{
  display: flex;
}
.cartNumber{
  margin-top: -.5rem;
}
