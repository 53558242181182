.cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin: 20px;
}

.pagination {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
