.cart-card {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
}

.product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}

.productName {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #333333;
}

.productPrice {
  font-size: 16px;
  font-weight: 500;
  color: #2e8540;
}

.product-quantity {
  font-size: 14px;
  color: #777777;
}

.cart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cart-card {
  animation: fade-in 0.3s ease-out; 
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0 1rem 0 1rem;
  display: flex;

}

.cartIcon {
  margin-top: 5px;
}

.product-display{
  width: 150px;
  margin: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.product-adder{
  display: flex;
  align-items: center;
  width: 30%
}
.product-name-div{
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn{
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  height: min-content;
}
