.Adresscontainer{
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 50%;
  padding: 40px;
}
.formAdress{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button[type="submit"] {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: max-content;
}
label{
  display: flex;
  flex-direction: column;
}
.button{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}