.total{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    gap: 2rem;
    
}